import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Breadcrumb from "../general/breadcrumbs";

class Privacy extends Component {
    render (){
        return (
          <div>
              {/*SEO Support*/}
              <Helmet>
                  <title>Patel Brothers | Privacy Policy</title>
              </Helmet>
              {/*SEO Support End */}

              <Breadcrumb title={'Privacy Policy'}/>

              <section className="section-b-space">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h2 style={{paddingTop: '20px'}}>Privacy Policy</h2>
                      <h3><strong><span>Privacy Policy</span></strong></h3>
                      <h3>
                      Protecting your information is our priority. This Privacy Policy describes how Patel Brothers (together with affiliates, “Patel Brothers”) collects, uses, or discloses personal information we receive through our websites (including www.patelbros.com or www.patelbrothers.shop), any mobile applications we provide, at our retail locations and through other related online or offline services we may offer (collectively, the “Services”). This policy also describes your choices about the collection and use of your information.
                      </h3>
                      <h3>
                        Please read the Privacy Policy carefully before you start to use our Services. By using the Services, you agree to be bound and abide by our posted Terms of Use and Privacy Policy. If you do not agree to both the Terms of Use and our Privacy Policy, or if you violate them in any way, your right to access or use the Services is terminated.
                        <em>Please see Sections 11-14 of our Terms of Use regarding your legal rights in any dispute involving our Services.</em>
                      </h3>
                      <h2 style={{paddingTop: '20px'}}>1.&nbsp;&nbsp;&nbsp;&nbsp; How We Collect Information</h2>
                      <h3>We may collect information about you by various means, including:</h3>
                      <ul style={{marginLeft: '70px'}}>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Directly from you, both online and offline;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Through our websites, mobile apps, and other connected devices and services;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>From other third party sources and social media platforms that you may use to engage with us, as well as from our affiliates, partners or third parties, including but not limited to consumer research firms and public databases, for the purposes provided below, and/or to supplement information we already possess; and</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>By combining information from different sources, including online and offline data.</li>
                      </ul>
                      <h2 style={{paddingTop: '20px'}}>2.&nbsp;&nbsp;&nbsp;&nbsp; Types of Information We Collect</h2>
                      <h3>In order to better provide you with our Services, we may collect the following categories of information:</h3>
                      <ul style={{marginLeft: '70px'}}>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Profile and subscription information</em>, such as a username and password, your name, mailing address, e-mail address, phone number, and usage and subscription preferences, which may include signing up to some of our Services or other communications from us or selected third parties;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Transactional information</em> including payment information and shopping history if you shop with us, and any loyalty program, discounts and other promotional activities you engage in with us;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Survey information</em> in response to questions we may send you through the Services, including for research purposes and possibly in connection with a contest or promotion;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>User Content</em> you may provide to publicly post via the Services if we permit (e.g., product comments, photos, and other materials) along with any information you provide about yourself and the content—this may also include metadata associated with your content (such as location and date/time information embedded in pictures);</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Communications</em> between you and us, such as via e-mail, mail, phone or other channels;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Offline activity</em> you engage in with us, such as when visiting our retail locations; and</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Online User Activity</em> described in the next section.</li>
                      </ul>
                      <h3>If you provide us with information regarding another individual, you represent that you have that person’s consent to give us his or her information and to permit us to use the information in accordance with this policy.<br/><br/>Job applicants may also provide us information as part of their employment application that includes such things as the applicant’s name, email, social media profile, resume, and cover letter. We do not use this information for any purpose other than to evaluate the individual for employment with us.<span>&nbsp; </span>Job applicants may provide additional information for routine background checks to a third party provider of such services, under specific privacy terms and consents that will be provided at the time of collection.</h3>
                      <h2 style={{paddingTop: '20px'}}>3.&nbsp;&nbsp;&nbsp;&nbsp; Online User Activity, Cookies and Information Collected by Other Automated Means</h2>
                      <h3>Cookies are a commonly-used web technology that allow websites to store and retrieve certain information on a user’s system, and track users’ online activities. We, our service providers, and our business partners may collect information about your use of our websites and mobile apps by such automated means, including but not limited to cookies, pixels, SDKs and other similar technologies.</h3>
                      <h3>Cookies and similar technologies can help us automatically identify you when you return to our website or app. Cookies help us review website traffic patterns and improve the website, determine what Services are popular. We can also use such information to deliver customized content and advertising to users of the Services whose behavior indicates that they are interested in a particular subject area.</h3>
                      <h3>When you use the Services, the information we may collect by automated means includes, for example:</h3>
                      <ul style={{marginLeft: '70px'}}>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Usage Details</em> about your interaction with our Services (such as the date, time, and length of visits, and specific pages or content accessed during the visits, search terms, frequency of the visits, referring website addresses);</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Device Information</em> including the IP address and other details of a device that you use to connect with our Services (such as device type and unique device identifier, operating system, browser type, mobile network information, and the device's telephone number)</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Stored information</em> you affirmatively provide the Services with access to, such as pictures, video and contacts stored on your device; and</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Location information</em> where you choose to provide the website or app with access to real-time information about your device’s location, note however that if you block our access to location information, some parts of the Services may be inaccessible or non-functional.</li>
                      </ul>
                      <h3>We may also ask advertisers or other partners to serve ads or services to your device, which may use cookies or similar technologies placed by us or the third party. If a user does not want information collected through the use of cookies, most browsers allow the visitor to reject cookies, but if you choose to decline cookies, you may not be able to fully experience the interactive features our Services provide. We may share non-personal information obtained via cookies with our advertisers and affiliates. Because there is not yet a consensus on how companies should respond to web browser-based do-not-track (“DNT”) mechanisms, we do not respond to web browser-based DNT signals at this time. We and our service providers and business partners may collect information about users’ online activities over time and across third-party websites.</h3>
                      <h2 style={{paddingTop: '20px'}}>4.&nbsp;&nbsp;&nbsp;&nbsp; How We Use Information We Collect</h2>
                      <h3>We may use the information we obtain about you for purposes allowed by applicable laws, including:</h3>
                      <ul style={{marginLeft: '70px'}}>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Provide our Services to you, including to manage your purchases, transactions, and payments with us and provide product recall information or other important services;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Respond to your requests, questions and comments and provide customer support;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Tailor the content we display to you in our Services and communications, including advertising, and offerproducts and services that we believe may be of interest to you;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Operate, evaluate and improve our programs, our websites and other products and services we offer (including to develop new services);</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Establish and maintain any account you create for our Services;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Offer and administer loyalty programs, coupons, promotions, sweepstakes or other contests;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Analyze and enhance our communications and strategies (including by identifying when emails sent to you have been received and read);</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Inform you about changes to this Privacy Policy and our Terms of Use and other policies;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Comply with and enforce as needed applicable legal requirements, industry standards, our policies and our contractual rights;</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Monitor the performance of our Services including metrics such as total number of visitors, traffic, and demographic patterns; and</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>Diagnose or fix technology problems.</li>
                      </ul>
                      <h3>We may also use or share information in an anonymized or aggregate manner for many purposes such as research, analysis, modeling, marketing, and advertising, as well as improvement of our Services.</h3>
                      <h2 style={{paddingTop: '20px'}}>5.&nbsp;&nbsp;&nbsp;&nbsp; Online Advertising</h2>
                      <h3>You may see us advertising on various websites, web pages, social media and other platforms. Please note that online advertising service do not always provide us with complete information about the platforms or website on which our advertising may appear. If you believe that our advertising is being displayed through websites or channels thatpromote inappropriate or offensive content, please contact us.</h3>
                      <h3>We may serve advertisements, and allow third parties to serve advertisements, through our Services. These advertisements are sometimes targeted and served to particular users and may come from third party organizations called “ad networks.” Ad networks can include a number of third party ad servers, ad agencies, ad technology vendors and research firms. Advertisements served through our Services may be targeted to users who fit a certain general profile category, which may be inferred from information you provide to us or, based on your website or mobile app usage patterns, or based on your activity on other website or online services as may be available to the ad networks.</h3>
                      <h2 style={{paddingTop: '20px'}}>6.&nbsp;&nbsp;&nbsp;&nbsp; How We Share Your Information</h2>
                      <h3>We will not disclose your personal information to third parties without your consent, except in the following circumstances.</h3>
                      <h3>We may share your information as permitted by law, including, for example, with our corporate affiliates, and service providers that we believe need the information to perform a technology, business, or other professional function for us (examples include IT services, maintenance and hosting of our Services, payment processors, shipping and returns partners, marketing partners, and other service providers). We only provide such vendors with information so they can perform their required functions on our behalf.</h3>
                      <h3>If you voluntarily share or submit any User Content including contact information (e.g., name) or content (e.g., picture), or commentary (e.g., forums, message boards, chats) for public posting through our Services, or link it to any social media platforms, that post and any content or information associated with it may become available to the public. See our Terms of Use for more information about how we may use such User Content.</h3>
                      <h3>We also may disclose information about you (i) if we are required to do so by law or legal process, (ii) when we believe disclosure is necessary to prevent harm or financial loss, (iii) in connection with an investigation of suspected or actual fraudulent or illegal activity; or (iv) under exigent circumstances to protect the personal safety of our staff, users or the public.</h3>
                      <h3>We reserve the right to transfer the information we maintain in the event we sell or transfer all or a portion of our business or assets. If we engage in such a sale or transfer, we will make reasonable efforts to direct the recipient to use your personal information in a manner that is consistent with this Privacy Policy.</h3>
                      <h3>We may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your information (e.g., e-mail, name, address, telephone number) is not transferred to the third party. Instead, we may share anonymous data with trusted partners to help perform statistical analysis, and then we would send you an email or postal mail, on their behalf.</h3>
                      <h3>Where appropriate, we will limit sharing of your information in accordance with the choices you have provided us and applicable law.</h3>
                      <h2 style={{paddingTop: '20px'}}>7.&nbsp;&nbsp;&nbsp;&nbsp; Your Privacy Choices</h2>
                      <h3>We offer you certain choices about what information we collect from you, how we use and disclose the information, and how we communicate with you.</h3>
                      <ul style={{marginLeft: '70px'}}>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Marketing Emails</em>: From time to time, we may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may be notified when you open an email from us or click on a link therein. You may choose not to receive marketing emails from us by clicking on the unsubscribe link in the marketing emails you receive from us. Even if you opt out of receiving such communications, we will continue sending you non-marketing email communications, such as administrative or transaction alerts, or information about changes to our terms of service.</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Cookies</em>: Web browsers may offer users the ability to disable receiving certain types of cookies; however, ifcookies are disabled, some features or functionality of our websites and mobile apps may not function</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>Targeted Advertising</em>: Some of our business partners that collect information about your activities on our websites and in our mobile apps may be members of organizations or programs that provide you with choices regarding the use of your browsing behavior for purposes of targeted advertising. For example, you may opt out of receiving targeted advertising on websites through members of the Network Advertising Initiative byclicking <u>here</u> or the Digital Advertising Alliance by clicking <u>here</u>. Mobile app users may opt out of receiving targeted advertising in mobile apps through members of the Digital Advertising Alliance by installing the AppChoices mobile app, available <u>here</u>, and selecting the desired choices. Please note that we also may work with companies that offer their own opt-out mechanisms and may not participate in the opt-out mechanisms that we linked above.</li>
                      <li style={{fontSize: '24px', fontWeight: '400', letterSpacing: '0.03em', color: '#777777', display: 'list-item'}}>
                      <em>User Account</em>: You may terminate your user account or any loyalty program you participate in by contacting us as provided below. We may retain any account information or User Content<span> for internal purposes or as otherwise provided in this Privacy Policy and our Terms of Use</span>.</li>
                      </ul>
                      <h2 style={{paddingTop: '20px'}}>8.&nbsp;&nbsp;&nbsp;&nbsp; State-Specific Notices</h2>
                      <h3>For California residents, the California Consumer Protection Act (the “CCPA”) provides various rights to individuals and households with respect to the collection and use of personal information that we have collected about you. Among other rights under the CCPA, you have the right to request that we: (i) disclose to you any personal information that we have about you; (ii) delete personal information that we have about you (subject to certain exceptions); or (iii) not “sell” your information to a third party (excluding qualified service providers), as that term is interpreted under the CCPA. It is unlawful for us to discriminate against you because you exercised any of your rights under the CCPA. If you are a California resident, you can submit such requests to us via the contact information provided below and we will complete the request within the timeframe permitted by law.</h3>
                      <h3>For Nevada residents, please note that we do not sell personal information as defined by Nevada law (Ch. 603A, Sec. 1.6). You can submit a request to us regarding the sale of such information via the contact information provided below.</h3>
                      <h2 style={{paddingTop: '20px'}}>9.&nbsp;&nbsp;&nbsp;&nbsp; Links to Other Websites and Third Party Content</h2>
                      <h3>Our Services may contain links to other websites or apps. This can include social media integrations. Please be aware that we are not responsible for the content or privacy practices of such other websites or apps, and we encourage you to be aware when you leave our Services and to read the privacy statements of any other website or app that collects personal information.</h3>
                      <h2 style={{paddingTop: '20px'}}>10.&nbsp; How We Protect Information</h2>
                      <h3>We endeavor to maintain reasonable administrative, technical and physical safeguards designed to protect the personal information we maintain against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use. However, we cannot ensure the security of any information you transmit to us, or guarantee that this information will not be accessed, disclosed, altered, or destroyed. We will make any legally required disclosures in the event of any compromise of personal information. To the extent the law allows us to provide such notification via e-mail or conspicuous posting on the Services, you agree to accept notice in that form.</h3>
                      <h2 style={{paddingTop: '20px'}}>11.&nbsp; Children</h2>
                      <h3>We do not knowingly collect personal data from children under the age of 13 on our Services. If you have reason to believe that a child under the age of 13 has provided personal information to us through our Services, please contact us, and we will endeavor to delete that information from our systems. In the event that we learn that we have collected personal information from a child under age 13 without parental consent, we will endeavor to delete that information as quickly as possible. If you believe that we might have any information from or about a child under 13, please contact us as directed below.</h3>
                      <h3>If you are a California resident under the age of 18, do not share any personal information on any public content channel we may offer (e.g., User Content). If you do post any such information, you can request removal by contacting us as directed below.</h3>
                      <h2 style={{paddingTop: '20px'}}>12.&nbsp; International Data Transfers and Use</h2>
                      <h3>Our Services are controlled and operated by us from the United States and are not intended to subject us to the laws or jurisdiction of any state, country or territory other than those of the United States. Any information you provide through use of the Services may be stored and processed, transferred between and accessed from the United States and other countries which may not guarantee the same level of protection of personal information as the one in which you reside. However, we will handle your personal information in accordance with this Privacy Policy regardless of where your personal information is stored or accessed.</h3>
                      <h3>If you are visiting from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your personal information, potentially including sensitive data, by us from your region to countries which do not have data protection laws that provide the same level of protection that exists in countries in the European Economic Area, including the United States. By providing your personal information, you consent to any transfer and processing in accordance with this Privacy Policy.</h3>
                      <h2 style={{paddingTop: '20px'}}>13.&nbsp; Updates to Our Privacy Policy</h2>
                      <h3>We may revise or update this Privacy Policy at any time. We will post the updated version on our Services, and may notify you of any significant changes to our Privacy Policy. We will indicate on our Privacy Policy when it was most recently updated. Your continued use of our Services after such updates will constitute (a) acknowledgment of the change and agreement to abide and be bound by the updated Privacy Policy.</h3>
                      <h2 style={{paddingTop: '20px'}}>14.&nbsp; Contacting Us</h2>
                      <h3>If you have any questions, concerns or comments about this Privacy Policy, our privacy practices, or if you would like us to update information or preferences you provided to us, please contact us at shop@patelbros.com</h3>
                      <h3>This Privacy Policy was last updated April 8th, 2020</h3>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )
    }
}

export default Privacy;
