import React, {Component} from 'react';
import HeaderOne from './general/headers/header-one';

class App extends Component {
    render() {
        return (
          <div>
              <HeaderOne />
              {this.props.children}
          </div>
        );
    }
}

export default App;
