import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import ReactLoading from 'react-loading';
import Select from 'react-select';
import {
  isMobile
} from "react-device-detect";
import FooterOne from "../general/footers/footer-one";

class SplashPage extends Component {

    constructor (props) {
        super (props)

        this.state = {
          bakeries: null,
          selectedBakerySlug: null
        };
    }

    componentDidMount() {
      axios.get('https://patelbrothers.shop/api/bakery/bakeries')
        .then(({data}) => {
          let bakeries = data.bakeries.map(b => { return {label: b.name, value: b.store_slug}})
          this.setState({selectedBakerySlug: bakeries[0].value, bakeries });

        })
        .catch(error => {
          this.setState({bakeries: []});
          console.log(error);
        })
    }

    selectBakery = (e) => {

    }

    render () {
      if (!this.state.bakeries) {
        return (
          <div style={{padding: '20px 0'}}>
            <center>
              <ReactLoading type={'spin'} color={'#129e51'} height={300} width={300} />
            </center>
          </div>
        )
      }

        return (
            <div>
                {/*Login section*/}
                <section className={`splash-page section-b-space`} style={{backgroundImage: `${isMobile ? 'url(https://swad.nyc3.digitaloceanspaces.com/mobile-splash-bg.png)' : 'url(https://swad.nyc3.digitaloceanspaces.com/splash-bg.png)'}`}}>

                  <div className="container">
                      <div className="row splash-row-main">
                            <div className="col-md-12 splash-col">
                              <div className="card splash-card">
                                <div className="card-body splash-card-body">
				  <center>
                                  <img  src={`https://swad.nyc3.cdn.digitaloceanspaces.com/patelbros/logo.png`} className="splash-img img-fluid" alt="Patel Brothers Logo"/>
				  </center>
                                  <p style={{textAlign: 'center', textTranform: 'none', fontWeight: 'bold'}}>Please Select A Patel Brothers Bakery Location</p>

                                  {this.state.bakeries ?
                                  <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                      <center>
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          defaultValue={this.state.bakeries[0]}
                                          isSearchable={false}
                                          name="bakery"
                                          onChange={(selected) => {
                                            this.setState({selectedBakerySlug: selected.value});
                                          }}
                                          options={this.state.bakeries}
                                        />
                                      </center>
                                    </div>
                                      <center>
                                      {this.state.selectedBakerySlug ?
                                        (
                                          <Link to={`/${this.state.selectedBakerySlug}/menu`} className="btn btn-primary">
                                            Open Menu
                                          </Link>
                                        )
                                        :
                                        <button style={{width: '50%'}} className="btn btn-primary" disabled>
                                          Select A Store
                                        </button>
                                      }
                                    </center>
                                  </div>
                                  : null }
                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
                  <h3 className="tag-line" style={{fontSize: '20px', marginTop: '20px', textAlign: 'center'}}>"Celebrating Our Food...Our Culture"</h3>
                </section>

                <FooterOne />
            </div>
        )
    }
}

export default SplashPage;
