import React, {Component} from 'react';

class FooterOne extends Component {
  render () {
      return (
          <footer className="footer-light">
              <div className="sub-footer ">
                  <div className="container">
                      <div className="row">
                          <div className="col-xl-12 col-md-12 col-sm-12">
                              <div className="footer-end">
                                  <p className="text-center">
                                    <i className="fa fa-copyright" aria-hidden="true"></i>&copy; 2023 Patel Brothers |
                                    &nbsp;<a href="https://patelsfreshkitchen.com/policies/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> |
                                    &nbsp;<a href="https://patelsfreshkitchen.com/policies/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
      );
  }
}


export default FooterOne;
