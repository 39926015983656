import React, {Component} from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';
let QRCode = require('qrcode.react');
let currency = require('currency.js');

let testMenu = [];

class QRMenuPage extends Component {

    constructor (props) {
        super (props)

        this.state = {
          menu: null,
          detail_category: null,
          detail_item: null
        };
    }

    componentDidMount() {
      axios.post('https://patelbrothers.shop/api/bakery/menu', {store_slug: this.props.match.params.store_slug})
        .then(({data}) => {
          this.setState({menu: data.menu})

        })
        .catch(error => {
          this.setState({menu: testMenu})
          console.log(error);
        })
    }

    categoryDetails = (category) => {
      this.setState({detail_category: category, detail_item: category.products[0]});
      window.scrollTo(0, 0)
    }

    hideCategoryDetails = () => {
      this.setState({detail_category: null, detail_item: null});
    }

    selectDetailItem = (product) => {
      this.setState({detail_item: product});
    }

    render () {
      if (!this.state.menu) {
        return (
          <div style={{padding: '20px 0'}}>
            <center>
              <ReactLoading type={'spin'} color={'#129e51'} height={300} width={300} />
            </center>
          </div>
        )
      }

      return (
        <section className="menu">

        <div className="qr-btn">
          <div style={{backgroundColor: 'white', padding: '10px'}}>
            <QRCode value={`https://patelsfreshkitchen.com/${this.props.match.params.store_slug}/menu`} />
          </div>
          <p style={{paddingTop: '8px', textAlign: 'center', fontWeight: 'bold', marginBottom: '0px', color: 'white'}}>SCAN ME</p>
        </div>

          <div className={`bg left-bg ${this.state.detail_category ? 'hidden' : ''}`}></div>
          <div className={`bg right-bg ${this.state.detail_category ? 'hidden' : ''}`}></div>
          <div className="container">
            <div className="row">

              <div className="col-lg-12 col-md-12 col-sm-12 text-center" style={{marginTop: `${this.props.topMargin ? '70px' : '0'}`}}>
                <img src={`https://swad.nyc3.digitaloceanspaces.com/categories/bakery-banner.png`} className="menu-logo" alt="Patel's Fresh Kitchen" />
              </div>


              {this.state.detail_category ?
                (
                  <div className="col-lg-12 col-md-12 col-sm-12 details">
                    <h3 className="category-detail-name">{this.state.detail_category.name}</h3>

                    {this.state.detail_category.products.map((product, p_idx) => {
                      return (
                        <p className={`category-product-detail-name ${product.name.toLowerCase() == this.state.detail_item.name.toLowerCase() ? 'active' : ''}`} onClick={() => this.selectDetailItem(product)} key={p_idx}>
                          {product.name} - <span className="category-product-detail-price">${currency(product.price).value.toFixed(2)}</span>
                        </p>
                      );
                    })}

                    <div className="product-detail-section row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <h3>
                            {this.state.detail_item.name} - ${currency(this.state.detail_item.price).value.toFixed(2)}
                            <p style={{fontSize: '12px'}}>
                                {this.state.detail_item.dietary}
                            </p>
                        </h3>
                        <h4>Ingredient List</h4>
                        <p>{this.state.detail_item.ingredients}</p>
                        <h4>Allergen Advice</h4>
                        <p>{this.state.detail_item.allergen_advice}</p>
                        <p style={{fontSize: '12px', fontWeight: "bold"}}><em>* All items are processed in the same machinery as wheat, dairy products</em></p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <img className="product-detail-nutrition" src={this.state.detail_item.nutritional_table_url} alt="Nutrition Table" />
                      </div>
                    </div>

                    <p className="close-details" onClick={() => this.hideCategoryDetails()}>
                      Click Here To Return to Menu
                    </p>
                  </div>
                )
                :
                (
                  <div className="col-lg-12 col-md-12 col-sm-12 menu-section">
                    <div className="row">
                      {this.state.menu.filter(c => c.products.length > 0).map((category, c_idx) => {
                        return (
                          <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 bakery-section" key={c_idx}>
                            <h1 onClick={() => this.categoryDetails(category)} className="category-name">{category.name}</h1>
                            <p className="product-names">
                              {category.products.sort((a, b) => {
                                if ( currency(a.price).value > currency(b.price).value ) return 1;
                                if ( currency(a.price).value < currency(b.price).value ) return -1;
                                return 0;
                              }).map((product, p_idx) => {
                                return (
                                    <section className="product-menu-section" key={p_idx}>
                                        <p className="product-name">
                                            {product.name}
                                            { product.dietary && product.dietary.length > 0 ?
                                                <p style={{fontSize: '12px', marginBottom: '0'}}>
                                                    {product.dietary}
                                                </p>
                                                :
                                                null
                                            }
                                        </p>
                                        <span className="spacing-span">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                                        {product.deals && product.deals.length > 0 ?
                                            <p className="product-name">
                                                {product.deals}
                                            </p>
                                            :
                                            <p className="product-name">
                                                ${currency(product.price).value.toFixed(2)}
                                            </p>
                                        }
                                    </section>
                                );
                              })}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )
              }
            </div>
          </div>

        </section>
      );
    }
}

export default QRMenuPage;
