import React, {Component} from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import QRMenuPage from "./menu_qr";

class PrintableQRMenuPage extends Component {

    constructor (props) {
        super (props)
    }

    render () {

      return (
      <>
      <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <button style={{marginTop: '30px', fontWeight: 'bold', fontSize: '20px'}} className="btn btn-primary" onClick={handlePrint}>Print this out!</button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>

        <div style={{marginTop: '20px', height: '10px', backgroundColor: 'black'}} />

        <QRMenuPage ref={el => (this.componentRef = el)} topMargin={'70px'} match={{params: {store_slug: this.props.match.params.store_slug}}} />

      </>
      );
    }
}

export default PrintableQRMenuPage;
