import React, {Component} from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';
let QRCode = require('qrcode.react');

class QRCodePage extends Component {

    constructor (props) {
        super (props)

        this.state = {

        };
    }

    componentDidMount() {

    }

    render () {
        return (
            <section className="menu">
                <center>
                    <div className="large-qr-btn">
                        <div style={{backgroundColor: 'white', padding: '10px'}}>
                            <QRCode value={`https://patelsfreshkitchen.com/${this.props.match.params.store_slug}/menu`} size={250}/>
                        </div>
                        <p style={{paddingTop: '8px', textAlign: 'center', fontWeight: 'bold', marginBottom: '0px', color: 'white'}}>SCAN ME</p>
                    </div>
                </center>
            </section>
        );
    }
}

export default QRCodePage;
