import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.css';

import SplashPage from "./components/home/splash";
import MenuPage from "./components/bakery/menu";
import QRMenuPage from "./components/bakery/menu_qr";
import PrintableQRMenuPage from "./components/bakery/printable_menu_qr";
import PrintableQRCodePage from "./components/bakery/printable_qr";
import Layout from './components/app'
import Terms from './components/policies/terms'
import Privacy from './components/policies/privacy-policy'
import PageNotFound from './components/general/404'

class Root extends React.Component {
    render() {
        return(
  				<BrowserRouter basename={'/'} >
  					<ScrollContext>
              <Layout>
                <Switch>
                  <Route exact path={`${process.env.PUBLIC_URL}/`} component={SplashPage} />

                  <Route exact path={`${process.env.PUBLIC_URL}/:store_slug/menu`} component={MenuPage}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/:store_slug/qr_menu`} component={QRMenuPage}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/:store_slug/printable_qr_menu`} component={PrintableQRMenuPage}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/:store_slug/printable_qr_code`} component={PrintableQRCodePage}/>

                  <Route exact path={`${process.env.PUBLIC_URL}/policies/terms-of-service`} component={Terms}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/policies/privacy-policy`} component={Privacy}/>
                  <Route path="*" component={PageNotFound} />
                </Switch>
              </Layout>
				  </ScrollContext>
				</BrowserRouter>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
